import React, { useEffect, useRef, useState } from "react";
import PessoaService from "../../Services/pessoaService";
import { Card as CardPF } from "primereact/card";
import { Divider } from "primereact/divider";
import * as S from "./style";
import { useNavigate } from "react-router-dom";
import FileService from "../../Services/fileService";
import { Image } from "primereact/image";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Menu } from "primereact/menu";
import { TieredMenu } from 'primereact/tieredmenu';
import DeclaracaoPdf from "../Reports/Filiacao";
import ResidenciaPdf from "../Reports/Residencia";
import { BlockUI } from "primereact/blockui";
import { Button } from 'primereact/button';
import jwtDecode from "jwt-decode";

function FormDetalhar(props) {
  const [loading, setLoading] = useState(true);
  const [pessoa, setPessoa] = useState({});
  const [imagemPerfil, setImagemPerfil] = useState();
  /* flag para bloquear a página durante o carregamento */
  const [block, setBlock] = useState(false);
  const [pessoaSelecionada, setPessoaSelecionada] = useState(null);
  const [permissao, setPermissao] = useState(null);

  const navigate = useNavigate();

  const ref = useRef();

  const menu = useRef(null);

  const menuRelAdvogado = useRef(null);

  const service = new PessoaService();
  const fileService = new FileService();

  const EstadoCivilEnum = [
    "Solteiro(a)",
    "Casado(a)",
    "Divorciado(a)",
    "União Estável",
    "Viúvo(a)",
  ];

  const APOSENTADORIA = 1;
  const SALARIO_MATERNIDADE = 2;
  const SEGURO_DEFESO = 3;

  const OUTOGRANTE = 1;
  const ROGO = 2;

  function abrirMenu(event, pessoa) {
    menu.current.toggle(event);
    setPessoaSelecionada(pessoa);
  }

  function abrirMenuRelAdvogados(event, pessoa) {
    menuRelAdvogado.current.toggle(event);
    setPessoaSelecionada(pessoa);
  }

  const itemsMenuAdvogado = [
    {
      label:'Procuração',
      icon:'pi pi-file-pdf',
      command: () =>{
        setBlock(true);
        // Relatório antigo
        // PescadorProfissional(pessoaSelecionada);
        service
          .gerarRelatorioProcuracao(pessoaSelecionada)
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/pdf",
            });

            const fileURL = URL.createObjectURL(blob);

            window.open(fileURL);
          })
          .catch((error) => {
            console.log("erro", error);
          })
          .finally(() => {
            setBlock(false);
          });
      },
    },
    {
      separator:true
    },
    {
        label:'Contrato',
        icon:'pi pi-file-pdf',
        items:[
            {
                label:'Aposentadoria',
                icon:'pi pi-file-pdf',
                command: () =>{
                  setBlock(true);
                  // Relatório antigo
                  // PescadorProfissional(pessoaSelecionada);
                  service
                    .gerarRelatorioContrato(pessoaSelecionada, APOSENTADORIA)
                    .then((response) => {
                      const blob = new Blob([response.data], {
                        type: "application/pdf",
                      });
          
                      const fileURL = URL.createObjectURL(blob);
          
                      window.open(fileURL);
                    })
                    .catch((error) => {
                      console.log("erro", error);
                    })
                    .finally(() => {
                      setBlock(false);
                    });
                },
                
            },
            {
                separator:true
            },
            {
                label:'Salário maternidade',
                icon:'pi pi-file-pdf',
                command: () =>{
                  setBlock(true);
                  // Relatório antigo
                  // PescadorProfissional(pessoaSelecionada);
                  service
                    .gerarRelatorioContrato(pessoaSelecionada, SALARIO_MATERNIDADE)
                    .then((response) => {
                      const blob = new Blob([response.data], {
                        type: "application/pdf",
                      });
          
                      const fileURL = URL.createObjectURL(blob);
          
                      window.open(fileURL);
                    })
                    .catch((error) => {
                      console.log("erro", error);
                    })
                    .finally(() => {
                      setBlock(false);
                    });
                },
            },
            {
              separator:true
            },
            {
              label:'Seguro defeso',
              icon:'pi pi-file-pdf',
              command: () =>{
                setBlock(true);
                // Relatório antigo
                // PescadorProfissional(pessoaSelecionada);
                service
                  .gerarRelatorioContrato(pessoaSelecionada, SEGURO_DEFESO)
                  .then((response) => {
                    const blob = new Blob([response.data], {
                      type: "application/pdf",
                    });
        
                    const fileURL = URL.createObjectURL(blob);
        
                    window.open(fileURL);
                  })
                  .catch((error) => {
                    console.log("erro", error);
                  })
                  .finally(() => {
                    setBlock(false);
                  });
              },
            }
        ]
    },
    {
      separator:true
    },
    {
      label:'PROCURAÇÃO/INSS',
      icon:'pi pi-file-pdf',
      items:[
            {
              label:'Outorgante',
              icon:'pi pi-file-pdf',
              command: () =>{
                setBlock(true);
                // Relatório antigo
                // PescadorProfissional(pessoaSelecionada);
                service
                  .gerarRelatorioNormativo(pessoaSelecionada, OUTOGRANTE)
                  .then((response) => {
                    const blob = new Blob([response.data], {
                      type: "application/pdf",
                    });
        
                    const fileURL = URL.createObjectURL(blob);
        
                    window.open(fileURL);
                  })
                  .catch((error) => {
                    console.log("erro", error);
                  })
                  .finally(() => {
                    setBlock(false);
                  });
              },
          },
          {
              separator:true
          },
          {
              label:'A Rogo',
              icon:'pi pi-file-pdf',
              command: () =>{
                setBlock(true);
                // Relatório antigo
                // PescadorProfissional(pessoaSelecionada);
                service
                  .gerarRelatorioNormativo(pessoaSelecionada, ROGO)
                  .then((response) => {
                    const blob = new Blob([response.data], {
                      type: "application/pdf",
                    });
        
                    const fileURL = URL.createObjectURL(blob);
        
                    window.open(fileURL);
                  })
                  .catch((error) => {
                    console.log("erro", error);
                  })
                  .finally(() => {
                    setBlock(false);
                  });
              },
          },
      ]
    },
    {
      separator:true
    },
    {
      label:'Evanir',
      icon:'pi pi-file-pdf',
      items:[
            {
              label:'Procuração',
              icon:'pi pi-file-pdf',
              command: () =>{
                setBlock(true);
                service
                  .gerarRelatorioNormativoEvanir(pessoaSelecionada)
                  .then((response) => {
                    const blob = new Blob([response.data], {
                      type: "application/pdf",
                    });
        
                    const fileURL = URL.createObjectURL(blob);
        
                    window.open(fileURL);
                  })
                  .catch((error) => {
                    console.log("erro", error);
                  })
                  .finally(() => {
                    setBlock(false);
                  });
              },
          },
          {
            label:'Contrato',
            icon:'pi pi-file-pdf',
            command: () =>{
              setBlock(true);
              service
                .gerarRelatorioContratoEvanir(pessoaSelecionada)
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
      
                  const fileURL = URL.createObjectURL(blob);
      
                  window.open(fileURL);
                })
                .catch((error) => {
                  console.log("erro", error);
                })
                .finally(() => {
                  setBlock(false);
                });
            },
        },
      ]
    },
];
  

  const items = [
    {
      label: "Emitir declaração de",
      items: [
        {
          label: "Filiação",
          icon: "pi pi-file-pdf",
          command: () => {
            DeclaracaoPdf(pessoaSelecionada);
          },
        },
        {
          label: "Residência",
          icon: "pi pi-file-pdf",
          command: () => {
            ResidenciaPdf(pessoaSelecionada);
          },
        },
        {
          label: "Residência a Rogo",
          icon: "pi pi-file-pdf",
          command: () => {
            setBlock(true);
            service
              .gerarRelatorioResidenciaRogo(pessoaSelecionada)
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });

                const fileURL = URL.createObjectURL(blob);

                window.open(fileURL);
              })
              .catch((error) => {
                console.log("erro", error);
              })
              .finally(() => {
                setBlock(false);
              });
          },
        },
        {
          label: "FLPP",
          icon: "pi pi-file-pdf",
          command: () => {
            setBlock(true);
            // Relatório antigo
            // PescadorProfissional(pessoaSelecionada);
            service
              .gerarRelatorioFlpp(pessoaSelecionada)
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });

                const fileURL = URL.createObjectURL(blob);

                window.open(fileURL);
              })
              .catch((error) => {
                console.log("erro", error);
              })
              .finally(() => {
                setBlock(false);
              });
          },
        },
        {
          label: "SDPA",
          icon: "pi pi-file-pdf",
          command: () => {
            setBlock(true);
            service
              .gerarRelatorio(pessoaSelecionada)
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });

                const fileURL = URL.createObjectURL(blob);

                window.open(fileURL);
              })
              .catch((error) => {
                console.log("erro", error);
              })
              .finally(() => {
                setBlock(false);
              });
          },
        },
        {
          label: "GPS",
          icon: "pi pi-file-pdf",
          command: () => {
            setBlock(true);
            service
              .gerarRelatorioGps(pessoaSelecionada)
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });

                const fileURL = URL.createObjectURL(blob);

                window.open(fileURL);
              })
              .catch((error) => {
                console.log("erro", error);
              })
              .finally(() => {
                setBlock(false);
              });
          },
        },
        {
          label: "Seguro Especial",
          icon: "pi pi-file-pdf",
          command: () => {
            setBlock(true);
            service
              .gerarRelatorioSeguro(pessoaSelecionada)
              .then((response) => {
                console.log(response);

                const blob = new Blob([response.data], {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });

                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = `seguro-especial-${pessoaSelecionada.nome}.docx`;
                link.click();
              })
              .catch((error) => {
                console.log("erro", error);
              })
              .finally(() => {
                setBlock(false);
              });
          },
        },
      ],
    },
  ];

  const Genero = ["Masculino", "Feminino", "Outros"];

  useEffect(() => {
    const buscarPessoa = async () => {
      service
        .findById(props.idPessoa)
        .then((response) => {
          setLoading(false);
          setPessoa(response.data);
          buscarPerfil(response.data.fotoPerfil);
          const token = jwtDecode(localStorage.getItem("accessToken"));
          setPermissao(token.roles[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (props.idPessoa && loading === true) {
      buscarPessoa();
    }
  }, []);

  async function buscarPerfil(nome) {
    await fileService.buscarPerfil(nome).then((response) => {
      var base64Flag = "data:image/jpeg;base64,";
      setImagemPerfil(base64Flag + response.data);
    });
  }

  //Redireciona para a página de edicao passando o id para edição.
  function handleRedirectEdit(idPessoa) {
    navigate("/editar-pessoas", {
      state: { idPessoa: idPessoa, paginaAtual: props.paginaAtual, modoEdicao: true },
    });
  }

  return (
    <S.FormDetalharStyle>
      <form>
        <BlockUI
          fullScreen
          blocked={block}
          template={
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "5em" }} />
          }
        />
        <div className="detalhar-form">
          <CardPF ref={ref}>
            <h5>Informações do cadastro</h5>
            <Divider></Divider>
            <div className="bs-content">
              <div className="row">
                <div className="col-sm-8">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Nome: </b>
                        {pessoa.nome}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">CPF: </b>
                        {pessoa.cpf}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">RG: </b>
                        {pessoa.rg}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Data de emissão: </b>
                        {pessoa.dataEmissao}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Data de nascimento: </b>
                        {pessoa.dataNascimento}{" "}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Gênero: </b>
                        {Genero[pessoa.genero - 1]}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Nome da Mãe: </b>
                        {pessoa.nomeMae}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Nome do Pai: </b>
                        {pessoa.nomePai}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Estado Civil: </b>
                        {EstadoCivilEnum[pessoa.estadoCivil - 1]}{" "}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Celular: </b>
                        {pessoa.celular}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Endereço: </b>
                        {pessoa.endereco} nº{" "}
                        {pessoa.numeroCasa === null ? "S/N" : pessoa.numeroCasa}
                        , {pessoa.bairro}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Município: </b>
                        {pessoa.municipio}, {pessoa.estado}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">CEP: </b>
                        {pessoa.cep}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Número do título: </b>
                        {pessoa.numeroTitulo}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Número do NIT: </b>
                        {pessoa.numeroNit}{" "}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Email: </b>
                        {pessoa.email}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Número do CEI: </b>
                        {pessoa.numeroCei}{" "}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Número PIS: </b>
                        {pessoa.numeroPis}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Data de filiação: </b>
                        {pessoa.dataFiliacao}{" "}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Senha INSS/GOV: </b>
                        {pessoa.senhaInssGov}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Profissão: </b>
                        {pessoa.profissao}{" "}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Naturalidade: </b>
                        {pessoa.naturalidade}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">SISRGP 4.0: </b>
                        {pessoa.sisrgp40}{" "}
                      </div>
                    </div>
                  </div>

                  {pessoa && permissao === "ADMIN" && (<div className="row">
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Cadastrado por: </b>
                        {pessoa && permissao === "ADMIN" ? (pessoa.usuarioCriador ? pessoa.usuarioCriador.fullName : 'Não informado') : 'Carregando...'}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="info">
                        <b className="detalhar-label">Última edição por: </b>
                        {pessoa && permissao === "ADMIN"? (pessoa.ultimoEditor ? pessoa.ultimoEditor.fullName : 'Não informado') : 'Carregando...'}
                      </div>
                    </div>
                  </div>)}
                </div>

                <div className="col-sm-4">
                  {imagemPerfil != null && (
                    <div>
                      <h5>Perfil</h5>
                      <Image
                        src={imagemPerfil}
                        alt="Image"
                        width="250"
                        preview
                      />
                      {/* <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" onClick={deleteImagemPerfil} /> */}
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-sm-12">
                      <div className="info">
                        <b className="detalhar-label">Observação: </b>
                        <textarea
                          type="text"
                          id="observacao"
                          name="observacao"
                          className="form-control"
                          rows={8}
                          disabled="true"
                          value={pessoa.observacao}
                        />
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </CardPF>
        </div>
      </form>

      <div className="button-container">
        <button
          type="button"
          className="btn btn-success"
          onClick={(e) =>
            navigate("/buscar-pessoas", {
              state: { paginaAtual: props.paginaAtual },
            })
          }
        >
          <i className="pi pi-arrow-left" /> Voltar
        </button>

        <ReactToPrint content={() => ref.current}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <button
                type="button"
                className="btn btn-primary detalhar-buttons imprimir-button"
                onClick={handlePrint}
              >
                Imprimir
              </button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>

        <Menu model={items} popup ref={menu} id="popup_menu" />
        <button
          type="button"
          className="btn btn-danger btn-table btn-pdf"
          onClick={(event) => abrirMenu(event, pessoa)}
          aria-controls="popup_menu"
          title="Gerar Documentos"
          aria-haspopup
        >
          Gerar Documentos <i className="pi pi-file-pdf" />
        </button>

        <TieredMenu model={itemsMenuAdvogado} popup ref={menuRelAdvogado} id="overlay_tmenu" />
        <Button label="Advocacia " onClick={(event) => abrirMenuRelAdvogados(event, pessoa)} aria-haspopup aria-controls="overlay_tmenu">
          <i className="pi pi-shield" />
        </Button>
        
        
        <button
          onClick={() => handleRedirectEdit(pessoa.id)}
          type="button"
          className="btn btn-primary btn-table"
          title="Editar Dados"
        >
          Editar Dados <i className="pi pi-user-edit" />
        </button>
      </div>
    </S.FormDetalharStyle>
  );
}

export default FormDetalhar;
